import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import Markdown from "./markdown";
import clsx from "clsx";
import Image from "./image";

const FooterLink = ({
  path,
  label
}) => {
  return (
    <li className="relative">
      <Link className="inline-block text-white hover:text-primary transition"
        to={path}>
        {label}
      </Link>
    </li>
  )
}

const Footer = () => {

  const query = useStaticQuery(graphql`
    query {
      mdx(fields: {filename: {eq: "layout.footer"}}) {
        frontmatter {
          logo {
            src {
                childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            alt
          }
          linksTitle
          links {
            label
            path
          }
          caption
          phone
          email
        }
      }
    }
  `);

  const {
    mdx: {
      frontmatter: {
        logo,
        linksTitle,
        links,
        caption,
        phone,
        email
      }
    }
  } = query;

  const {
    entity
  } = useSiteMetadata();
  
  return (
    <>
      <footer className="bg-primary/80 text-white py-24 relative">
        <div className="absolute w-full h-full top-0 left-0 overflow-hidden">
          <svg xmlns="http://www.w3.org/2000/svg" width="486.064" height="490.709" viewBox="0 0 486.064 490.709"
            className="absolute bottom-[-8%] right-[-5%] max-h-[380px] opacity-20">
            <g id="Group_1" data-name="Group 1" transform="translate(-621.632 -391.181)">
              <path id="Path_7" data-name="Path 7" d="M874.125,566.029c-1.922-1.686-4.3-3.892-6.793-5.946-26.623-21.894-72.085-22.51-98.47,10.366-11.979,14.927-17.041,31.7-15.133,50.57,2.012,19.913,11.932,35.579,26.656,48.626,16.064,14.234,34.593,20.074,55.928,17.276,27.208-3.564,51.313-23.887,57.985-50.431,6.743-26.822-.21-50.266-20.175-70.462M746.867,536.71c39.945-41.6,113.949-43.539,158.957,3.564,40.371,42.246,41.621,112.711-3.033,155.889-44.478,43.013-115.263,39.973-156.877-2.941-44.261-45.64-40.824-115.4.953-156.511" transform="translate(125.631 154.937)" fill="#071333"/>
              <path id="Path_8" data-name="Path 8" d="M923.775,442.6c-1.8.977-3,1.653-4.231,2.29q-32.463,16.829-64.9,33.7c-2.153,1.125-3.764.793-5.814-.184a282.4,282.4,0,0,0-89.113-25.689c-.745-.087-1.481-.25-2.7-.458.375-20.284.75-40.539,1.132-61.077,59.1,1.512,114.446,18.5,165.632,51.421" transform="translate(183.92 0)" fill="#071333"/>
              <path id="Path_9" data-name="Path 9" d="M810.545,573.335a118.056,118.056,0,0,0-64.995,29.527c-12.448-55.785-24.76-110.951-37.069-166.12.177-.092.354-.189.531-.283q50.648,68.277,101.534,136.876" transform="translate(117.99 61.513)" fill="#071333"/>
              <path id="Path_10" data-name="Path 10" d="M813.387,391.207c-.337,20.151-.668,39.942-.981,58.728-7.132.281-15.019.682-22.911.887a267.245,267.245,0,0,0-64.839,9.644c-1.71.474-2.736.559-3.465-1.455q-7.73-21.354-15.684-42.621c-.821-2.189-.186-2.955,1.826-3.727,21.057-8.057,42.631-14.288,64.945-17.55,11.956-1.748,24.048-2.552,36.079-3.752,1.41-.142,2.837-.092,5.031-.153" transform="translate(113.475 0.035)" fill="#071333"/>
              <path id="Path_11" data-name="Path 11" d="M750.424,403.231c5.618,15.255,11.128,30.225,16.751,45.494l-12.552,4.748a255.675,255.675,0,0,0-48.138,24.1c-5.842,3.757-11.547,7.731-17.241,11.713-1.552,1.085-2.618,1.028-3.943-.311-7.512-7.59-15.038-15.166-22.663-22.645-1.545-1.517-1.245-2.42.184-3.632,9.734-8.253,19.229-16.8,29.206-24.744,14.481-11.538,30.489-20.758,46.85-29.345,3.67-1.927,7.521-3.512,11.547-5.375" transform="translate(54.307 16.371)" fill="#071333"/>
              <path id="Path_12" data-name="Path 12" d="M703.769,445.618c3.481,3.481,6.948,6.977,10.451,10.437,1.21,1.193,1.623,2.292.073,3.524-7.217,5.731-13.356,12.595-19.883,19.043-15.505,15.323-28.359,32.706-39.458,51.412-1.146,1.932-2.3,2.349-4.373,1.512q-8.024-3.241-16.184-6.149c-1.948-.689-2.208-1.554-1.432-3.351a318.917,318.917,0,0,1,33.93-59.49c5.856-8.017,11.734-16.026,18.656-23.194a46.88,46.88,0,0,0,3.236-3.941c1.441-1.868,2.769-2.217,4.585-.29,3.373,3.585,6.932,6.993,10.413,10.474l-.014.014" transform="translate(14.842 57.957)" fill="#071333"/>
              <path id="Path_13" data-name="Path 13" d="M632.272,581.753c-1.036.849-2.333.606-3.529.736-7.17.792-7.3.774-7.047-6.3a306.286,306.286,0,0,1,14.151-82.266c1.486-4.689,3.17-9.314,4.656-14,.583-1.826,1.42-2.4,3.3-1.644q7.769,3.124,15.628,6.017c1.993.729,1.729,1.759.981,3.259A277.135,277.135,0,0,0,645.19,524.83a289.771,289.771,0,0,0-12.177,54.2,5.19,5.19,0,0,1-.74,2.724" transform="translate(0 117.882)" fill="#071333"/>
              <path id="Path_14" data-name="Path 14" d="M657.874,664.781c-2.257-2.665-3.255-5.967-4.764-8.988a297.738,297.738,0,0,1-23.774-67.551,292.111,292.111,0,0,1-7.417-57.039c-.052-2,.554-2.639,2.547-2.653,5.991-.047,6.135-.116,5.929,5.887a273.734,273.734,0,0,0,3.9,55.676,292.825,292.825,0,0,0,7.783,32.836c4.205,14.366,9.9,28.126,15.8,41.834" transform="translate(0.385 186.61)" fill="#071333"/>
            </g>
          </svg>
        </div>
        <div className="contain relative">
          <div className="grid md:grid-cols-3 gap-x-6 gap-y-12">
            <div>
              <Markdown content={caption} />
              <div className="mb-4 flex flex-col gap-y-1">
                <span>Phone: <a href={`tel:${phone}`}>{phone}</a></span>
                <span>Email: <a href={`mailto:${email}`}>{email}</a></span>
              </div>
              <Image src={logo.src}
                alt={logo.alt} 
                className="max-w-[160px] mt-2"/>
            </div>
            {links.length > 0 &&
              <div>
                {linksTitle.length > 0 && <h5 className="font-medium text-xl mb-3 md:mb-4">{linksTitle}</h5>}
                <ul className="flex flex-col gap-y-2">
                  {links.map((i,k) => (
                    <FooterLink key={k}
                      {...i} />
                  ))}
                </ul>
              </div>}

          </div>
          <div className="text-sm flex flex-col md:flex-row gap-x-8 gap-y-4 pt-8 pb-2">
            <p>&copy; {new Date().getFullYear()} {entity}. All rights reserved.</p>
            <Link to="/privacy"
              className="underline">Privacy Policy</Link>
          </div>
          <div className="text-sm">
            <p>Allyom Traffic Control acknowledges the traditional owners and custodians of the and, and respects Eders past, present and future.</p>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;