import clsx from "clsx";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Markdown = ({
  content,
  className,
  ...props
}) => {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]}
      className={clsx("[&_p]:mb-4 [&_h1]:font-bold [&_h2]:font-bold [&_h3]:font-bold [&_h4]:font-bold [&_h5]:font-bold [&_h1]:text-4xl [&_h2]:text-3xl [&_h3]:text-2xl [&_h4]:text-xl [&_h5]:text-lg md:[&_h1]:text-5xl md:[&_h2]:text-4xl md:[&_h3]:text-3xl md:[&_h4]:text-2xl md:[&_h5]:text-xl [&_h1]:text-navy [&_h2]:text-navy [&_h3]:text-navy [&_h4]:text-navy [&_h5]:text-navy [&_h1]:mb-4 [&_h2]:mb-4 [&_h3]:mb-4 [&_h4]:mb-4 [&_h5]:mb-4 [&_h1]:pt-4 [&_h2]:pt-4 [&_h3]:pt-4 [&_h4]:pt-4 [&_h5]:pt-4 [&_ul]:mb-4 [&_ul]:list-outside [&_ol]:list-outside [&_ul]:ml-5 [&_ol]:ml-5 [&_ul_li]:list-disc [&_ol_li]:list-decimal [&_li]:mb-2 [&_a]:underline", className)}
      {...props}>{content}</ReactMarkdown>
  )
}

export default Markdown;