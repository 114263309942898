import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "./image";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import clsx from "clsx";

const NavItem = ({ path, label, children = [], isActive = false }) => {
  const [dropdown, setDropdown] = React.useState(false);
  const ref = React.useRef();
  React.useEffect(() => {
    return globalHistory.listen(({action}) => {
      if(action === 'PUSH') setDropdown(false)
    })
  }, [setDropdown]);
  React.useEffect(() => {
    const handler = (event) => {
      if(dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    }
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);
  return (
    <li className="relative"
      ref={ref}>
      {children?.length > 0 
        ? <div className="group">
          <button type="button"
            className={clsx("flex justify-between items-center transition whitespace-nowrap font-medium font-heading", isActive ? "text-[color:var(--theme-color-primary)]" : "text-[color:var(--theme-color-text)] hover:text-[color:var(--theme-color-secondary)]")}
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}>
            <span>{label}</span>
            <svg className="ml-1 w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
          <div className={clsx('z-10 w-auto font-normal lg:bg-[var(--header-dropdown-color-bg)] lg:rounded divide-y divide-gray-100 lg:shadow-lg lg:absolute min-w-[120px] lg:mt-1', {
              'hidden': !dropdown,
              'block': dropdown
            })}>
            <ul className="py-1">
              {children.map((i,k) => {
                return (
                  <li key={k}
                    className="whitespace-nowrap">
                    <Link to={i.path} 
                      className="inline-block py-2 px-4 transition-colors font-bold text-[color:var(--header-dropdown-color-links)] hover:text-[color:var(--header-dropdown-color-active-links)]">
                      {i.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        : <Link
        className={clsx("inline-block transition whitespace-nowrap font-medium font-heading", isActive ? "text-[color:var(--theme-color-primary)]" : "text-[color:var(--theme-color-text)] hover:text-[color:var(--theme-color-secondary)]")}
        to={path}
      >
        {label}
      </Link>}
    </li>
  );
};

const Header = ({path}) => {
  const [mobileActive, setMobileActive] = React.useState(false);

  const handleSetActive = React.useCallback((active) => {
    setMobileActive(active);
    active 
      ? document.documentElement.classList.add('clip') 
      : document.documentElement.classList.remove('clip');
  }, [setMobileActive])

  React.useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        handleSetActive(false);
      }
    });
  }, [handleSetActive]);

  const query = useStaticQuery(graphql`
    query {
      mdx(fields: { filename: { eq: "layout.header" } }) {
        frontmatter {
          logo {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            alt
          }
          links {
            label
            path
            children {
              label
              path
            }
          }
        }
      }
    }
  `);

  const {
    mdx: {
      frontmatter: { 
        logo,
        links
      },
    }
  } = query;

  return (
    <>
      <header className="z-10 relative">
        <nav className="py-2 lg:py-0 bg-white">
          <div className="contain flex flex-nowrap items-center gap-x-3 lg:gap-x-6 py-2 lg:py-4">
            <div className="flex flex-1 items-center">
              <Link to="/">
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  objectFit="contain"
                  className="max-w-[110px]"
                />
              </Link>
            </div>
            <ul className="hidden lg:flex flex-1 flex-row items-center justify-end py-4 gap-x-8">
              {links.map((i, k) => (
                <NavItem {...i} key={k} />
              ))}
            </ul>
            <button
              type="button"
              className="py-4 items-center text-[color:var(--header-color-menu-icon)] lg:hidden"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => handleSetActive(true)}
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </nav>
      </header>

      <aside
        className={clsx(
          "lg:hidden bg-[var(--theme-color-background-alt)] absolute h-full w-[100vw] z-30 shadow-lg transition-[left] duration-500",
          {
            "left-[-150vw]": !mobileActive,
            "left-0": mobileActive,
          }
        )}>
        <div className="relative w-full h-full flex flex-col px-4 py-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-5 cursor-pointer fill-black hover:fill-primary-dark transition-all right-4 top-5 absolute lg:hidden"
            onClick={() => handleSetActive(false)}
          >
            <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
          </svg>
          <div className="flex lg:pl-4 py-2 lg:py-4 mb-4">
            <Link to="/">
              <Image
                src={logo.src}
                alt={logo.alt}
                className="max-w-[100px]"
              />
            </Link>
          </div>
          <ul className="flex flex-col gap-y-3 font-bold mb-8 flex-1">
            {links.map((i, k) => (
              <NavItem key={k} {...i} />
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Header;