import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";

const Image = ({
  src,
  alt,
  className,
  style,
  ...props
}) => {
  if(src === null || src === undefined) {
    return <></>
  }
  return (
    <>
    {src.childImageSharp !== null && src.childImageSharp !== undefined
      ? <GatsbyImage image={getImage(src)}
        alt={alt}
        className={className}
        style={style}
        {...props} />
      : <img src={src.publicURL}
          alt={alt}
          style={style}
          className={className} />}
    </>
  )
}

export default Image;