import * as React from "react"
import Footer from "./footer";
import Header from "./header";
import { navigate } from '@reach/router';

const themeStyles = {
  '--theme-color-primary': '#011741',
  '--theme-color-secondary': '#90C13E',
  '--theme-color-text': '#262626',
  '--theme-color-background': '#ffffff',
  '--theme-color-background-alt': '#EEEEEE',
  '--theme-color-border': '#E5E5E5',
  '--theme-color-button-text': '#ffffff',
  '--theme-color-button-text-hover': '#ffffff',
  '--theme-color-button-background': '#90C13E',
  '--theme-color-button-background-hover': '#82ae38',
  '--theme-color-button-border': '#90C13E',
  '--theme-color-button-border-hover': '#82ae38'
}

const Layout = ({location, children}) => {
  const [lastPath, setLastPath] = React.useState(null);

  React.useEffect(() => {
    if(lastPath !== location.pathname) {
      setLastPath(location.pathname);
    }
    if(lastPath !== '/' && location.hash) {
      navigate(location.hash)
    }
  }, [location, lastPath]);

  return (
    <div className="relative flex flex-col h-full text-black opacity-1"
      style={themeStyles}>
      <Header path={location.pathname} />
      <main className="grow">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout;